import React , { Component } from "react";

class HariKrishnaVirtualTour extends Component {
    // componentDidMount() {
    //     window.location.href =
    //         "http://mlz.gg/hk";
    // }
    render() {
        // return true;
        return(
            <iframe title='HK' style={{border:"none"}} width="100%" height="100%" src="https://mlz.gg/hkgroup" />
        )
    }
}

export default HariKrishnaVirtualTour;
